const contact = {
  phoneNumber: '',
  phoneNumberDisplay: '',
  address: "95 North Quay\nBrisbane City QLD 4000",
  email: "info@headmarkconsulting.com",
  coordinates: { lat: -27.469606, lng: 153.020877 },
  linkedIn: "https://www.linkedin.com/company/headmarkconsulting",
  sharepoint: "",
}

export default contact
