import React, { FC } from "react"
import { Link } from "gatsby"
import contact from "../support/contact"
import Logo from "./vector/logo"

const Header: FC = () => {
  const [menuOpen, setMenuOpen] = React.useState(
    typeof document !== "undefined" &&
      document.body.classList.contains("menu-open")
  )
  const toggleMenuOpen = () => setMenuOpen(!menuOpen)
  React.useEffect(() => {
    if (typeof document === "undefined") return
    if (!menuOpen) {
      document.body.classList.remove("menu-open")
      return
    }

    document.body.classList.add("menu-open")
    const listener = () => setMenuOpen(false)
    document.addEventListener("click", listener)
    return () => document.removeEventListener("click", listener)
  }, [menuOpen])
  return (
    <header className="home2-header">
      <div className="menu-trigger" onClick={toggleMenuOpen}></div>
      <div className="h2-header-upper-area d-none d-sm-block">
        <div className="container">
          <div className="row">
            <div className="col-8 d-none d-sm-block">
              <div className="welcome">
                <p className="m-0">
                  <span>
                    <i className="fa fa-angle-double-right"></i>
                  </span>
                  Defence Consulting and Business Analysts
                </p>
              </div>
            </div>
            <div className="col-4 header-social">
              <a href={contact.linkedIn} target="_blank" rel="nofollow">
                <i className="fa fa-linkedin"></i>
              </a>
              {/* <a
                href={contact.sharepoint}
                target="_blank"
                rel="nofollow"
                style={{ fontSize: "1.5rem", paddingLeft: "1rem" }}
              >
                Login
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="h2-header-middle-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-sm-12 col-12">
              <div className="logo">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-12 d-none d-sm-block">
              <div className="head-all-info-box justify-content-lg-end">
                <div className="head-info-box">
                  <div className="icon">
                    <span>
                      <i className="fa fa-map-marker"></i>
                    </span>
                  </div>
                  <div className="info">
                    <Link to="/contact">
                      <h6 className="title">Our Location</h6>
                      <p
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: contact.address.replace(/\n/g, ",<br/>"),
                        }}
                      ></p>
                    </Link>
                  </div>
                </div>
                {contact.phoneNumber && (<div className="head-info-box">
                  <div className="icon">
                    <span>
                      <i className="fa fa-phone"></i>
                    </span>
                  </div>
                  
                  <div className="info">
                    <a href={`tel:${contact.phoneNumber}`}>
                      <h6 className="title">Phone Number</h6>
                      <p className="text">{contact.phoneNumberDisplay}</p>
                    </a>
                  </div>
                </div>
                )}
                <div className="head-info-box">
                  <div className="icon">
                    <span>
                      <i className="fa fa-envelope"></i>
                    </span>
                  </div>
                  <div className="info">
                    <a href={`mailto: ${contact.email}`}>
                      <h6 className="title">Email Address</h6>
                      <p className="text">{contact.email}</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-area">
        <div className="menu">
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}
export default Header
